/* Setting Vue Router Routes */
const settings = [
  {
    path: '/settings',
    name: 'setting-list',
    component: () => import('@/views/setting/SettingList.vue'),
    meta: {
      layout: 'content',
      resource: 'Super',
      action: 'read'
    },
  },
  {
    path: '/settings/create',
    name: 'setting-create',
    component: () => import('@/views/setting/SettingCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'Super',
      action: 'create'
    },
  },
  {
    path: '/settings/:id',
    name: 'setting-view',
    component: () => import('@/views/setting/SettingView.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Super',
      action: 'read'
    },
  },
  {
    path: '/settings/edit/:id',
    name: 'setting-edit',
    component: () => import('@/views/setting/SettingEdit.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Super',
      action: 'update'
    },
  },
]

export default settings
