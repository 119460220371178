/* Country Vuex State Management
import { stateCountry, gettersCountry, mutationsCountry, actionsCountry} from './country'
*/
import axios from '@axios';

const stateCountry = {
  Countries: [],
  CountriesTotal: 0,
  Country: {}
}

const gettersCountry = {
};

const mutationsCountry = {
  COUNTRY_LIST_SET(state, value) {
    state.Countries = value.items;
    state.CountriesTotal = value.total;
  },
  COUNTRY_ITEM_SET(state, value) {
    state.Country = value;
  }
};

const actionsCountry = {
    fetchCountries(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${ctx.rootState.apiUrl}/api/countries`, queryParams)
          .then(response => {
            const { items, total } = response.data;
            ctx.commit('COUNTRY_LIST_SET', {
              items: items,
              total: total
            });
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchCountry(ctx, { id }) {
        return new Promise((resolve, reject) => {
            axios
            .get(`${ctx.rootState.apiUrl}/api/countries/${id}`)
            .then(response => {
              ctx.commit('COUNTRY_ITEM_SET', response.data);
              resolve(response)
            })
            .catch(error => reject(error))
        })
    },
    updateCountry(ctx, { id, entity }) {

      ctx.commit('COUNTRY_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/countries/${id}`, entity)
        .then(response => {
          ctx.commit('COUNTRY_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    createCountry(ctx, { entity }) {

      ctx.commit('COUNTRY_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .post(`${ctx.rootState.apiUrl}/api/countries/create`, entity)
        .then(response => {
          ctx.commit('COUNTRY_ITEM_SET', response.data); // allow for server-side data
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    fetchCountriesLookup(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        if (queryParams.id) {
          axios
            .get(`${ctx.rootState.apiUrl}/api/countries/${queryParams.id}`)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => reject(error));
        } else {
          axios
            .post(`${ctx.rootState.apiUrl}/api/countries`, queryParams)
            .then(response => {
              resolve(response.data.items);
            })
            .catch(error => reject(error));
        }
      });
    },
}

export {
  stateCountry,
  gettersCountry,
  mutationsCountry,
  actionsCountry
}