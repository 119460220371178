/* Address Vuex State Management
import { stateAddress, gettersAddress, mutationsAddress, actionsAddress} from './address'
*/
import axios from '@axios';

const stateAddress = {
  Addresses: [],
  AddressesTotal: 0,
  Address: {}
}

const gettersAddress = {
};

const mutationsAddress = {
  ADDRESS_LIST_SET(state, value) {
    state.Addresses = value.items;
    state.AddressesTotal = value.total;
  },
  ADDRESS_ITEM_SET(state, value) {
    state.Address = value;
  }
};

const actionsAddress = {
    fetchAddresses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${ctx.rootState.apiUrl}/api/addresses`, queryParams)
          .then(response => {
            const { items, total } = response.data;
            ctx.commit('ADDRESS_LIST_SET', {
              items: items,
              total: total
            });
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchAddress(ctx, { id }) {
        return new Promise((resolve, reject) => {
            axios
            .get(`${ctx.rootState.apiUrl}/api/addresses/${id}`)
            .then(response => {
              ctx.commit('ADDRESS_ITEM_SET', response.data);
              resolve(response)
            })
            .catch(error => reject(error))
        })
    },
    updateAddress(ctx, { id, entity }) {

      ctx.commit('ADDRESS_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/addresses/${id}`, entity)
        .then(response => {
          ctx.commit('ADDRESS_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    createAddress(ctx, { entity }) {

      ctx.commit('ADDRESS_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .post(`${ctx.rootState.apiUrl}/api/addresses/create`, entity)
        .then(response => {
          ctx.commit('ADDRESS_ITEM_SET', response.data); // allow for server-side data
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    fetchAddressesLookup(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        if (queryParams.id) {
          axios
            .get(`${ctx.rootState.apiUrl}/api/addresses/${queryParams.id}`)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => reject(error));
        } else {
          axios
            .post(`${ctx.rootState.apiUrl}/api/addresses`, queryParams)
            .then(response => {
              resolve(response.data.items);
            })
            .catch(error => reject(error));
        }
      });
    },
}

export {
  stateAddress,
  gettersAddress,
  mutationsAddress,
  actionsAddress
}