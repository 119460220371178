/* OrderExtra Vue Router Routes */
const orderextras = [
  {
    path: '/orderextras',
    name: 'orderextra-list',
    component: () => import('@/views/orderextra/OrderExtraList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/orderextras/create',
    name: 'orderextra-create',
    component: () => import('@/views/orderextra/OrderExtraCreate.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/orderextras/:id',
    name: 'orderextra-view',
    component: () => import('@/views/orderextra/OrderExtraView.vue'),
    props: true,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/orderextras/edit/:id',
    name: 'orderextra-edit',
    component: () => import('@/views/orderextra/OrderExtraEdit.vue'),
    props: true,
    meta: {
      layout: 'content',
    },
  },
]

export default orderextras
