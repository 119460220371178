/* OrderExtra Vuex State Management
import { stateOrderExtra, gettersOrderExtra, mutationsOrderExtra, actionsOrderExtra} from './orderextra'
*/
import axios from '@axios';

const stateOrderExtra = {
  OrderExtras: [],
  OrderExtrasTotal: 0,
  OrderExtra: {}
}

const gettersOrderExtra = {
};

const mutationsOrderExtra = {
  ORDEREXTRA_LIST_SET(state, value) {
    state.OrderExtras = value.items;
    state.OrderExtrasTotal = value.total;
  },
  ORDEREXTRA_ITEM_SET(state, value) {
    state.OrderExtra = value;
  }
};

const actionsOrderExtra = {
    fetchOrderExtras(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${ctx.rootState.apiUrl}/api/orderextras`, queryParams)
          .then(response => {
            const { items, total } = response.data;
            ctx.commit('ORDEREXTRA_LIST_SET', {
              items: items,
              total: total
            });
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchOrderExtra(ctx, { id }) {
        return new Promise((resolve, reject) => {
            axios
            .get(`${ctx.rootState.apiUrl}/api/orderextras/${id}`)
            .then(response => {
              ctx.commit('ORDEREXTRA_ITEM_SET', response.data);
              resolve(response)
            })
            .catch(error => reject(error))
        })
    },
    updateOrderExtra(ctx, { id, entity }) {

      ctx.commit('ORDEREXTRA_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/orderextras/${id}`, entity)
        .then(response => {
          ctx.commit('ORDEREXTRA_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    createOrderExtra(ctx, { entity }) {

      ctx.commit('ORDEREXTRA_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .post(`${ctx.rootState.apiUrl}/api/orderextras/create`, entity)
        .then(response => {
          ctx.commit('ORDEREXTRA_ITEM_SET', response.data); // allow for server-side data
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    fetchOrderExtrasLookup(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        if (queryParams.id) {
          axios
            .get(`${ctx.rootState.apiUrl}/api/orderextras/${queryParams.id}`)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => reject(error));
        } else {
          axios
            .post(`${ctx.rootState.apiUrl}/api/orderextras`, queryParams)
            .then(response => {
              resolve(response.data.items);
            })
            .catch(error => reject(error));
        }
      });
    },
}

export {
  stateOrderExtra,
  gettersOrderExtra,
  mutationsOrderExtra,
  actionsOrderExtra
}