<template>
  <layout-blank>
    <slot></slot>

    <v-snackbar v-for="(snackbar, index) in snackbars" :value="snackbar.open" :key="index" :timeout="snackbar.timeout" :color="snackbar.color || 'secondary'">
      {{ snackbar.message }}
      <template v-slot:action="{ }">
        <v-btn text
                @click="snackbar.open = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </layout-blank>
</template>

<script>
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue'
import store from '@/store'
import { computed } from '@vue/composition-api'

export default {
  components: {
    LayoutBlank,
  },
  setup() {
    const snackbars = computed(() => {
      return store.state.app.snackbars;
    });

    return {
      snackbars
    }
  }
}
</script>

<style>
</style>
