/* OrderStatus Vuex State Management
import { stateOrderStatus, gettersOrderStatus, mutationsOrderStatus, actionsOrderStatus} from './orderstatus'
*/
import axios from '@axios';

// read order statuses default from local storage
let OrderStatuses = {};
let OrderStatusesJson = window.localStorage.getItem("OrderStatuses");
if (OrderStatusesJson && OrderStatusesJson.length > 0) {
  OrderStatuses = JSON.parse(OrderStatusesJson);
}

const stateOrderStatus = {
  OrderStatuses: [],
  OrderStatusesTotal: 0,
  OrderStatus: {},
  OrderStatusById: OrderStatuses
}

const gettersOrderStatus = {
  OrderStatusByName(state) {
    if (!state.OrderStatusById) return null;
    return Object.keys(state.OrderStatusById).reduce((o, key) => {
      return Object.assign(o, {[state.OrderStatusById[key]]: key});
    }, {});
  },
  OrderStatusAllowBookAdditional(state, getters) {
    const OrderStatuses = ["Active", "Pending", "Quote", "Ready", "Held"];

    // need to check these have been loaded
    if (getters.OrderStatusByName[OrderStatuses[0]]) {
      let ids = OrderStatuses.map(x => getters.OrderStatusByName[x]);
      return ids;
    } else {
      return [];
    }
  },
  OrderStatusAllowDeclineQuote(state, getters) {
    const OrderStatuses = ["Quote Pending"];

    // need to check these have been loaded
    if (getters.OrderStatusByName[OrderStatuses[0]]) {
      let ids = OrderStatuses.map(x => getters.OrderStatusByName[x]);
      return ids;
    } else {
      return [];
    }
  },
  OrderStatusAllowBook(state, getters) {
    const OrderStatuses = ["Active", "Pending"];

    // need to check these have been loaded
    if (getters.OrderStatusByName[OrderStatuses[0]]) {
      let ids = OrderStatuses.map(x => getters.OrderStatusByName[x]);
      return ids;
    } else {
      return [];
    }
  },
  OrderStatusAllowConvertQuote(state, getters) {
    const OrderStatuses = ["Held"];

    // need to check these have been loaded
    if (getters.OrderStatusByName[OrderStatuses[0]]) {
      let ids = OrderStatuses.map(x => getters.OrderStatusByName[x]);
      return ids;
    } else {
      return [];
    }
  },
  OrderStatusDefaultFilterForOrderList(state, getters) {
    const OrderStatuses = ["Additional", "Held", "Ready", "In Manifest"];

    // need to check these have been loaded
    if (getters.OrderStatusByName[OrderStatuses[0]]) {
      let ids = OrderStatuses.map(x => getters.OrderStatusByName[x]);
      return ids;
    } else {
      return [];
    }
  },
  OrderStatusQuotes(state, getters) {
    const OrderStatuses = ["Quote", "Quote Pending"];

    // need to check these have been loaded
    if (getters.OrderStatusByName[OrderStatuses[0]]) {
      let ids = OrderStatuses.map(x => getters.OrderStatusByName[x]);
      return ids;
    } else {
      return [];
    }
  },
};

const mutationsOrderStatus = {
  ORDERSTATUS_LIST_SET(state, value) {
    state.OrderStatuses = value.items;
    state.OrderStatusesTotal = value.total;
  },
  ORDERSTATUS_ITEM_SET(state, value) {
    state.OrderStatus = value;
  },
  ORDERSTATUS_SET(state, value) {
    state.OrderStatusById = value.items.reduce((o, item) => {
      return Object.assign(o, {[item.Id]: item.Name});
    }, {});

    // also write to local storage
    window.localStorage.setItem("OrderStatuses", JSON.stringify(state.OrderStatusById));
  },
};

const actionsOrderStatus = {
  loadOrderStatusById(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ctx.rootState.apiUrl}/api/orderstatuses`, {})
        .then(response => {
          const { items, total } = response.data;
          ctx.commit('ORDERSTATUS_SET', {
            items: items,
            total: total
          });
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  fetchOrderStatuses(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ctx.rootState.apiUrl}/api/orderstatuses`, queryParams)
        .then(response => {
          const { items, total } = response.data;
          ctx.commit('ORDERSTATUS_LIST_SET', {
            items: items,
            total: total
          });
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  fetchOrderStatus(ctx, { id }) {
        return new Promise((resolve, reject) => {
            axios
            .get(`${ctx.rootState.apiUrl}/api/orderstatuses/${id}`)
            .then(response => {
              ctx.commit('ORDERSTATUS_ITEM_SET', response.data);
              resolve(response)
            })
            .catch(error => reject(error))
        })
    },
    // updateOrderStatus(ctx, { id, entity }) {

    //   ctx.commit('ORDERSTATUS_ITEM_SET', entity);  // update immediately
    //   return new Promise((resolve, reject) => {
    //     axios
    //     .put(`${ctx.rootState.apiUrl}/api/orderstatuses/${id}`, entity)
    //     .then(response => {
    //       ctx.commit('ORDERSTATUS_ITEM_SET', response.data); // allow for server-side data
    //       ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
    //       resolve(response)
    //     })
    //     .catch(error => {
    //       if (error.response && error.response.data) return reject(error.response.data);
    //       return reject(error);
    //     })
    //   })
    // },
    // createOrderStatus(ctx, { entity }) {

    //   ctx.commit('ORDERSTATUS_ITEM_SET', entity);  // update immediately
    //   return new Promise((resolve, reject) => {
    //     axios
    //     .post(`${ctx.rootState.apiUrl}/api/orderstatuses/create`, entity)
    //     .then(response => {
    //       ctx.commit('ORDERSTATUS_ITEM_SET', response.data); // allow for server-side data
    //       resolve(response)
    //     })
    //     .catch(error => {
    //       if (error.response && error.response.data) return reject(error.response.data);
    //       return reject(error);
    //     })
    //   })
    // },
}

export {
  stateOrderStatus,
  gettersOrderStatus,
  mutationsOrderStatus,
  actionsOrderStatus
}