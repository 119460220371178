/* OrderIssue Vuex State Management
import { stateOrderIssue, gettersOrderIssue, mutationsOrderIssue, actionsOrderIssue} from './orderissue'
*/
import axios from '@axios';

const stateOrderIssue = {
  OrderIssues: [],
  OrderIssuesTotal: 0,
  OrderIssue: {}
}

const gettersOrderIssue = {
};

const mutationsOrderIssue = {
  ORDERISSUE_LIST_SET(state, value) {
    state.OrderIssues = value.items;
    state.OrderIssuesTotal = value.total;
  },
  ORDERISSUE_ITEM_SET(state, value) {
    state.OrderIssue = value;
  }
};

const actionsOrderIssue = {
    fetchOrderIssues(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${ctx.rootState.apiUrl}/api/orderissues`, queryParams)
          .then(response => {
            const { items, total } = response.data;
            ctx.commit('ORDERISSUE_LIST_SET', {
              items: items,
              total: total
            });
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    resolveOrderIssue(ctx, { id, entity, comment }) {

      entity.comment = "\n\n" + comment;

      ctx.commit('ORDERISSUE_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/orderissues/${id}/resolve`, {
          ResolveComment: comment
        })
        .then(response => {
          ctx.commit('ORDERISSUE_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });

          // trigger a reload of order and the list being viewed
          ctx.dispatch('fetchOrder', {
            id: entity.OrderId
          });

          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    // fetchOrderIssue(ctx, { id }) {
    //     return new Promise((resolve, reject) => {
    //         axios
    //         .get(`${ctx.rootState.apiUrl}/api/orderissues/${id}`)
    //         .then(response => {
    //           ctx.commit('ORDERISSUE_ITEM_SET', response.data);
    //           resolve(response)
    //         })
    //         .catch(error => reject(error))
    //     })
    // },
    // updateOrderIssue(ctx, { id, entity }) {

    //   ctx.commit('ORDERISSUE_ITEM_SET', entity);  // update immediately
    //   return new Promise((resolve, reject) => {
    //     axios
    //     .put(`${ctx.rootState.apiUrl}/api/orderissues/${id}`, entity)
    //     .then(response => {
    //       ctx.commit('ORDERISSUE_ITEM_SET', response.data); // allow for server-side data
    //       ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
    //       resolve(response)
    //     })
    //     .catch(error => {
    //       if (error.response && error.response.data) return reject(error.response.data);
    //       return reject(error);
    //     })
    //   })
    // },
    createOrderIssue(ctx, { order, title, comment }) {

      let entity = {
        OrderId: order.Id,
        Title: title,
        Notes: comment
      };
      return new Promise((resolve, reject) => {
        axios
        .post(`${ctx.rootState.apiUrl}/api/orderissues/create`, entity)
        .then(response => {
          ctx.commit('ORDERISSUE_ITEM_SET', response.data); // allow for server-side data
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    // fetchOrderIssuesLookup(ctx, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     if (queryParams.id) {
    //       axios
    //         .get(`${ctx.rootState.apiUrl}/api/orderissues/${queryParams.id}`)
    //         .then(response => {
    //           resolve(response.data)
    //         })
    //         .catch(error => reject(error));
    //     } else {
    //       axios
    //         .post(`${ctx.rootState.apiUrl}/api/orderissues`, queryParams)
    //         .then(response => {
    //           resolve(response.data.items);
    //         })
    //         .catch(error => reject(error));
    //     }
    //   });
    // },
}

export {
  stateOrderIssue,
  gettersOrderIssue,
  mutationsOrderIssue,
  actionsOrderIssue
}