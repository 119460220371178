/* Order Vuex State Management
import { stateOrder, gettersOrder, mutationsOrder, actionsOrder} from './order'
*/
import axios from '@axios';

const stateOrder = {
  Orders: [],
  OrdersTotal: 0,
  Order: {}
}

const gettersOrder = {
};

const mutationsOrder = {
  ORDER_LIST_SET(state, value) {
    state.Orders = value.items;
    state.OrdersTotal = value.total;
  },
  ORDER_ITEM_SET(state, value) {
    state.Order = value;
  }
};

const actionsOrder = {
    fetchOrders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${ctx.rootState.apiUrl}/api/orders`, queryParams)
          .then(response => {
            const { items, total } = response.data;
            ctx.commit('ORDER_LIST_SET', {
              items: items,
              total: total
            });
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchOrder(ctx, { id }) {
        return new Promise((resolve, reject) => {
            axios
            .get(`${ctx.rootState.apiUrl}/api/orders/${id}`)
            .then(response => {
              ctx.commit('ORDER_ITEM_SET', response.data);
              resolve(response)
            })
            .catch(error => reject(error))
        })
    },
    updateOrder(ctx, { id, entity }) {

      ctx.commit('ORDER_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/orders/${id}`, entity)
        .then(response => {
          ctx.commit('ORDER_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: "Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    acknowledgeOrder(ctx, { order }) {

      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/orders/${order}/acknowledge`)
        .then(response => {
          ctx.commit('ORDER_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: "Acknowledged." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    cancelOrder(ctx, { id, entity, comment }) {

      const orderStatusesByName = ctx.rootGetters["app/OrderStatusByName"];

      // set the data locally
      entity.StatusId = orderStatusesByName["Cancelled"];
      entity.StatusId_Name = "Cancelled";

      ctx.commit('ORDER_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/orders/${id}/cancel`, {
          comment
        })
        .then(response => {
          ctx.commit('ORDER_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: "Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    updateOrderQuote(ctx, { id, entity, quote }) {

      // set the data locally
      entity.QuotePrice = quote.QuotePrice;
      entity.QuoteBreakdownItems = quote.QuoteBreakdownItems;

      ctx.commit('ORDER_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/orders/${id}/quote`, quote)
        .then(response => {
          ctx.commit('ORDER_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: "Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    createOrder(ctx, { entity }) {

      ctx.commit('ORDER_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .post(`${ctx.rootState.apiUrl}/api/orders/create`, entity)
        .then(response => {
          ctx.commit('ORDER_ITEM_SET', response.data); // allow for server-side data
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    createOrderForMember(ctx, { entity }) {

      ctx.commit('ORDER_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .post(`${ctx.rootState.apiUrl}/api/orders/create-for-member`, entity)
        .then(response => {
          ctx.commit('ORDER_ITEM_SET', response.data); // allow for server-side data
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    createOrderForTesting(ctx, { entity }) {

      ctx.commit('ORDER_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .post(`${ctx.rootState.apiUrl}/api/orders/create-for-testing`, entity)
        .then(response => {
          ctx.commit('ORDER_ITEM_SET', response.data); // allow for server-side data
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    fetchOrdersLookup(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        if (queryParams.id) {
          axios
            .get(`${ctx.rootState.apiUrl}/api/orders/${queryParams.id}`)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => reject(error));
        } else {
          axios
            .post(`${ctx.rootState.apiUrl}/api/orders`, queryParams)
            .then(response => {
              resolve(response.data.items);
            })
            .catch(error => reject(error));
        }
      });
    },
    bookItemsOrder({ commit, rootGetters, rootState }, { id, entity, items }) {

      // validate we have an order - in the correct state and 1+ items with data
      const orderStatudId = entity.StatusId;
      const filterOrderStatuses = rootGetters["app/OrderStatusAllowBook"];
      if (!filterOrderStatuses.includes(orderStatudId.toString())) {
        commit('SNACKBAR_ADD', { color: "error", message: `Order status '${entity.StatusId_Name}' does not allow booking of items.` });
        return;
      }
      if (!items || items.length <= 0) {
        commit('SNACKBAR_ADD', { color: "error", message: 'Must book at least one item.' });
        return;
      }

      // send to server
      return new Promise((resolve, reject) => {
        axios
        .post(`${rootState.apiUrl}/api/orders/${id}/bookitems`, items)
        .then(response => {
          commit('ORDER_ITEM_SET', response.data); // allow for server-side data
          commit('SNACKBAR_ADD', { color: "secondary", message: "Items Booked." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      });
    },
    bookAdditionalItemsOrder({ commit, rootGetters, rootState }, { id, entity, items }) {

      // validate we have an order - in the correct state and 1+ items with data
      const orderStatudId = entity.StatusId;
      const filterOrderStatuses = rootGetters["app/OrderStatusAllowBookAdditional"];
      if (!filterOrderStatuses.includes(orderStatudId.toString())) {
        commit('SNACKBAR_ADD', { color: "error", message: `Order status '${entity.StatusId_Name}' does not allow booking of additional items.` });
        return;
      }
      if (!items || items.length <= 0) {
        commit('SNACKBAR_ADD', { color: "error", message: 'Must book at least one item.' });
        return;
      }

      // send to server
      return new Promise((resolve, reject) => {
        axios
        .post(`${rootState.apiUrl}/api/orders/${id}/bookadditionalitems`, items)
        .then(response => {
          commit('ORDER_ITEM_SET', response.data); // allow for server-side data
          commit('SNACKBAR_ADD', { color: "secondary", message: "Items Booked." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      });
    },
    acceptOrderQuote({ commit, rootState, rootGetters }, { entity, comment }) {

      const orderStatudId = entity.StatusId;
      const orderStatusesByName = rootGetters["app/OrderStatusByName"];

      if (orderStatusesByName["Quote"] == orderStatudId) {

        // set the data locally
        entity.StatusId = orderStatusesByName["Held"];
        entity.StatusId_Name = "Held";

        commit('ORDER_ITEM_SET', entity);  // update immediately
        return new Promise((resolve, reject) => {
          axios
          .put(`${rootState.apiUrl}/api/orders/${entity.Id}/acceptquote`, {
            Comment: comment
          })
          .then(response => {
            commit('ORDER_ITEM_SET', response.data); // allow for server-side data
            commit('SNACKBAR_ADD', { color: "secondary", message: "Saved." });
            resolve(response)
          })
          .catch(error => {
            if (error.response && error.response.data) return reject(error.response.data);
            return reject(error);
          })
        });
      }
    },
    convertOrderQuote({ commit, rootState, rootGetters }, { entity, comment }) {

      const orderStatudId = entity.StatusId;
      const filterOrderStatuses = rootGetters["app/OrderStatusAllowBook"];
      const orderStatusesByName = rootGetters["app/OrderStatusByName"];

      if (!filterOrderStatuses.includes(orderStatudId.toString())) {

        // set the data locally
        entity.StatusId = orderStatusesByName["Quote Pending"];
        entity.StatusId_Name = "Quote Pending";

        commit('ORDER_ITEM_SET', entity);  // update immediately
        return new Promise((resolve, reject) => {
          axios
          .put(`${rootState.apiUrl}/api/orders/${entity.Id}/convertquote`, {
            Comment: comment
          })
          .then(response => {
            commit('ORDER_ITEM_SET', response.data); // allow for server-side data
            commit('SNACKBAR_ADD', { color: "secondary", message: "Saved." });
            resolve(response)
          })
          .catch(error => {
            if (error.response && error.response.data) return reject(error.response.data);
            return reject(error);
          })
        });
      }
    },
    declineQuote({ commit, rootState, rootGetters }, { entity, comment }) {

      console.log("declineQuote");

      const orderStatudId = entity.StatusId;
      const filterOrderStatuses = rootGetters["app/OrderStatusAllowDeclineQuote"];
      const orderStatusesByName = rootGetters["app/OrderStatusByName"];

      console.dir(orderStatudId);
      console.dir(filterOrderStatuses);

      if (filterOrderStatuses.includes(orderStatudId.toString())) {

        // set the data locally
        entity.StatusId = orderStatusesByName["Archived"];
        entity.StatusId_Name = "Archived";

        commit('ORDER_ITEM_SET', entity);  // update immediately
        return new Promise((resolve, reject) => {
          axios
          .put(`${rootState.apiUrl}/api/orders/${entity.Id}/declinequote`, {
            Comment: comment
          })
          .then(response => {
            commit('ORDER_ITEM_SET', response.data); // allow for server-side data
            commit('SNACKBAR_ADD', { color: "secondary", message: "Saved." });
            resolve(response)
          })
          .catch(error => {
            if (error.response && error.response.data) return reject(error.response.data);
            return reject(error);
          })
        });
      }
    },
    createadditionalOrder({ commit, rootState, rootGetters }, { entity, comment }) {

      return new Promise((resolve, reject) => {
        axios
        .post(`${rootState.apiUrl}/api/orders/${entity.Id}/createadditionalorder`)
        .then(response => {
          commit('ORDER_ITEM_SET', response.data); // allow for server-side data
          commit('SNACKBAR_ADD', { color: "secondary", message: "Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      });
    },
}

export {
  stateOrder,
  gettersOrder,
  mutationsOrder,
  actionsOrder
}