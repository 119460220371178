import { stateProductCategory, gettersProductCategory, mutationsProductCategory, actionsProductCategory} from './productcategory'
import { stateProduct, gettersProduct, mutationsProduct, actionsProduct} from './product'
import { stateAddress, gettersAddress, mutationsAddress, actionsAddress} from './address'
import { stateCountry, gettersCountry, mutationsCountry, actionsCountry} from './country'
import { stateUser, gettersUser, mutationsUser, actionsUser} from './user'
import { stateOrderStatus, gettersOrderStatus, mutationsOrderStatus, actionsOrderStatus} from './orderstatus'
import { stateMember, gettersMember, mutationsMember, actionsMember } from './member'
import { stateManifest, gettersManifest, mutationsManifest, actionsManifest} from './manifest'
import { stateOrder, gettersOrder, mutationsOrder, actionsOrder} from './order'
import { stateItem, gettersItem, mutationsItem, actionsItem} from './item'
import { stateOrderLog, gettersOrderLog, mutationsOrderLog, actionsOrderLog} from './orderlog'
import { stateOrderIssue, gettersOrderIssue, mutationsOrderIssue, actionsOrderIssue} from './orderissue'
import { stateInvoice, gettersInvoice, mutationsInvoice, actionsInvoice} from './invoice'
import { stateDashboard, gettersDashboard, mutationsDashboard, actionsDashboard} from './dashboard'
import { stateOrderExtra, gettersOrderExtra, mutationsOrderExtra, actionsOrderExtra} from './orderextra'
import { stateDiscountCode, gettersDiscountCode, mutationsDiscountCode, actionsDiscountCode} from './discountcode'
import { stateLog, gettersLog, mutationsLog, actionsLog} from './log'
import { stateSetting, gettersSetting, mutationsSetting, actionsSetting} from './setting'

import axios from '@axios';

export default {
  namespaced: true,
  state: {
    apiUrl: process.env.VUE_APP_API_URL,
    shallContentShowOverlay: false,
    snackbars: [],
    ...stateProductCategory,
    ...stateProduct,
    ...stateAddress,
    ...stateCountry,
    ...stateUser,
    ...stateOrderStatus,
    ...stateMember,
    ...stateManifest,
    ...stateOrder,
    ...stateItem,
    ...stateOrderLog,
    ...stateOrderIssue,
    ...stateInvoice,
    ...stateDashboard,
    ...stateOrderExtra,
    ...stateDiscountCode,
    ...stateLog,
    ...stateSetting,
  },
  getters: {
    ...gettersProductCategory,
    ...gettersProduct,
    ...gettersAddress,
    ...gettersCountry,
    ...gettersUser,
    ...gettersOrderStatus,
    ...gettersMember,
    ...gettersManifest,
    ...gettersOrder,
    ...gettersItem,
    ...gettersOrderLog,
    ...gettersOrderIssue,
    ...gettersInvoice,
    ...gettersDashboard,
    ...gettersOrderExtra,
    ...gettersDiscountCode,
    ...gettersLog,
    ...gettersSetting,
  },
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    SNACKBAR_ADD(state, value) {

      let timeout = value.timeout;
      if (timeout === undefined || timeout <= 0) timeout = 3000;
      value.timeout = timeout;

      // always open - but allow it to close
      value.open = true;

      // add to the state, keep index
      var snackbars = state.snackbars;
      let index = snackbars.push(value) - 1;
      state.snackbars = snackbars;

      // remove after time-out (plus a bit)
      setTimeout(() => {
        var snackbars = state.snackbars;
        snackbars.splice(index, 1);
        state.snackbars = snackbars;
      }, timeout + 1000)
    },
    ...mutationsProductCategory,
    ...mutationsProduct,
    ...mutationsAddress,
    ...mutationsCountry,
    ...mutationsUser,
    ...mutationsOrderStatus,
    ...mutationsMember,
    ...mutationsManifest,
    ...mutationsOrder,
    ...mutationsItem,
    ...mutationsOrderLog,
    ...mutationsOrderIssue,
    ...mutationsInvoice,
    ...mutationsDashboard,
    ...mutationsOrderExtra,
    ...mutationsDiscountCode,
    ...mutationsLog,
    ...mutationsSetting,
  },
  actions: {
    ...actionsProductCategory,
    ...actionsProduct,
    ...actionsAddress,
    ...actionsCountry,
    ...actionsUser,
    ...actionsOrderStatus,
    ...actionsMember,
    ...actionsManifest,
    ...actionsOrder,
    ...actionsItem,
    ...actionsOrderLog,
    ...actionsOrderIssue,
    ...actionsInvoice,
    ...actionsDashboard,
    ...actionsOrderExtra,
    ...actionsDiscountCode,
    ...actionsLog,
    ...actionsSetting,
  },
}
