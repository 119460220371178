/* Invoice Vue Router Routes */
const invoices = [
  {
    path: '/invoices',
    name: 'invoice-list',
    component: () => import('@/views/invoice/InvoiceList.vue'),
    meta: {
      layout: 'content',
      resource: 'Customers',
      action: 'read'
    },
  },
  {
    path: '/invoices/create',
    name: 'invoice-create',
    component: () => import('@/views/invoice/InvoiceCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'CustomerCare',
      action: 'create'
    },
  },
  {
    path: '/invoices/:id',
    name: 'invoice-view',
    component: () => import('@/views/invoice/InvoiceView.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Customers',
      action: 'read'
    },
  },
  {
    path: '/invoices/edit/:id',
    name: 'invoice-edit',
    component: () => import('@/views/invoice/InvoiceEdit.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'CustomerCare',
      action: 'update'
    },
  },
]

export default invoices
