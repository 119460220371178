<template>
    <v-list-item @click="isDark = !isDark">
      <v-list-item-icon class="me-2">
        <v-icon
          size="22"
          :key="isDark"
        >
          {{ isDark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ isDark ? "Light" : "Dark" }} Mode</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
  setup() {
    const { isDark } = useAppConfig()

    return {
      isDark,

      // Icons
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
}
</script>

<style>
</style>
