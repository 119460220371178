/* Order Vue Router Routes */
const orders = [
  {
    path: '/orders',
    name: 'order-list',
    component: () => import('@/views/order/OrderList.vue'),
    meta: {
      layout: 'content',
      resource: 'Orders',
      action: 'read'
    },
  },
  {
    path: '/orders-unheld',
    name: 'unheld-order-list',
    component: () => import('@/views/order/UnheldList.vue'),
    meta: {
      layout: 'content',
      resource: 'Orders',
      action: 'read'
    },
  },
  {
    path: '/customer-care',
    name: 'order-customercare',
    component: () => import('@/views/order/CustomerCare.vue'),
    meta: {
      layout: 'content',
      resource: 'CustomerCare',
      action: 'read'
    },
  },
  {
    path: '/quotes',
    name: 'order-quoteslist',
    component: () => import('@/views/order/QuotesList.vue'),
    meta: {
      layout: 'content',
      resource: 'Quotes',
      action: 'read'
    },
  },
  {
    path: '/book-in/additional/:id',
    name: 'order-bookadditionalitem',
    component: () => import('@/views/order/AddItems.vue'),
    meta: {
      layout: 'content',
      resource: 'BookIn',
      action: 'update'
    }
  },
  {
    path: '/book-in/create/:id',
    name: 'order-bookitem-create',
    component: () => import('@/views/order/OrderCreateForMember.vue'),
    meta: {
      layout: 'content',
      resource: 'BookIn',
      action: 'create'
    },
  },
  {
    path: '/quote/:id',
    name: 'order-quote',
    component: () => import('@/views/order/OrderQuote.vue'),
    meta: {
      layout: 'content',
      resource: 'Quotes',
      action: 'create'
    }
  },
  {
    path: '/book-in/:id',
    name: 'order-bookitem',
    component: () => import('@/views/order/BookItem.vue'),
    meta: {
      layout: 'content',
      resource: 'BookIn',
      action: 'create'
    }
  },
  {
    path: '/book-in',
    name: 'order-bookin',
    component: () => import('@/views/order/FindBooking.vue'),
    meta: {
      layout: 'content',
      resource: 'BookIn',
      action: 'read'
    }
  },
  // {
  //   path: '/orders/createtest',
  //   name: 'order-createtest',
  //   component: () => import('@/views/order/OrderCreateForTesting.vue'),
  //   meta: {
  //     layout: 'content',
  //     resource: 'Administration',
  //     action: 'create'
  //   },
  // },
  {
    path: '/orders/create',
    name: 'order-create',
    component: () => import('@/views/order/OrderCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'Orders',
      action: 'create'
    },
  },
  {
    path: '/orders/:id',
    name: 'order-view',
    component: () => import('@/views/order/OrderView.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Orders',
      action: 'read'
    },
  },
  {
    path: '/orders/edit/:id',
    name: 'order-edit',
    component: () => import('@/views/order/OrderEdit.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Orders',
      action: 'update'
    },
  },
  {
    path: '/orders/customs/:id',
    name: 'order-updateinfo',
    component: () => import('@/views/order/OrderUpdateCustoms.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Orders',
      action: 'update'
    },
  },
  {
    path: '/orders/print/:id',
    name: 'order-print',
    component: () => import('@/views/order/PrintLabel.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'BookIn',
      action: 'read'
    },
  }
]

export default orders
