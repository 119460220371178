/* Address Vue Router Routes */
const addresses = [
  {
    path: '/addresses',
    name: 'address-list',
    component: () => import('@/views/address/AddressList.vue'),
    meta: {
      layout: 'content',
      resource: 'Customers',
      action: 'read'
    },
  },
  {
    path: '/addresses/create',
    name: 'address-create',
    component: () => import('@/views/address/AddressCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'Customers',
      action: 'update'
    },
  },
  {
    path: '/addresses/:id',
    name: 'address-view',
    component: () => import('@/views/address/AddressView.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Customers',
      action: 'read'
    },
  },
  {
    path: '/addresses/edit/:id',
    name: 'address-edit',
    component: () => import('@/views/address/AddressEdit.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Customers',
      action: 'create'
    },
  },
]

export default addresses
