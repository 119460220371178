/* Manifest Vuex State Management
import { stateManifest, gettersManifest, mutationsManifest, actionsManifest} from './manifest'
*/
import axios from '@axios';

const stateManifest = {
  Manifests: [],
  ManifestsTotal: 0,
  Manifest: {}
}

const gettersManifest = {
};

const mutationsManifest = {
  MANIFEST_LIST_SET(state, value) {
    state.Manifests = value.items;
    state.ManifestsTotal = value.total;
  },
  MANIFEST_ITEM_SET(state, value) {
    state.Manifest = value;
  }
};

const actionsManifest = {
  fetchManifests(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ctx.rootState.apiUrl}/api/manifests`, queryParams)
        .then(response => {
          const { items, total } = response.data;
          ctx.commit('MANIFEST_LIST_SET', {
            items: items,
            total: total
          });
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  fetchManifest(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ctx.rootState.apiUrl}/api/manifests/${id}`)
        .then(response => {
          ctx.commit('MANIFEST_ITEM_SET', response.data);
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  updateManifest(ctx, { id, entity }) {
    ctx.commit('MANIFEST_ITEM_SET', entity);  // update immediately
    return new Promise((resolve, reject) => {
      axios
        .put(`${ctx.rootState.apiUrl}/api/manifests/${id}`, entity)
        .then(response => {
          ctx.commit('MANIFEST_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
    })
  },
  createManifest(ctx, { entity }) {
    ctx.commit('MANIFEST_ITEM_SET', entity);  // update immediately
    return new Promise((resolve, reject) => {
      axios
        .post(`${ctx.rootState.apiUrl}/api/manifests/create`, entity)
        .then(response => {
          ctx.commit('MANIFEST_ITEM_SET', response.data); // allow for server-side data
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
    })
  },
  fetchManifestsLookup(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      if (queryParams.id) {
        axios
          .get(`${ctx.rootState.apiUrl}/api/manifests/${queryParams.id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error));
      } else {
        axios
          .post(`${ctx.rootState.apiUrl}/api/manifests`, queryParams)
          .then(response => {
            resolve(response.data.items);
          })
          .catch(error => reject(error));
      }
    });
  },
}

export {
  stateManifest,
  gettersManifest,
  mutationsManifest,
  actionsManifest
}
