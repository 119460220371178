/* Log Vuex State Management
import { stateLog, gettersLog, mutationsLog, actionsLog} from './log'
*/
import axios from '@axios';

const stateLog = {
  Logs: [],
  LogsTotal: 0,
  Log: {}
}

const gettersLog = {
};

const mutationsLog = {
  LOG_LIST_SET(state, value) {
    state.Logs = value.items;
    state.LogsTotal = value.total;
  },
  LOG_ITEM_SET(state, value) {
    state.Log = value;
  }
};

const actionsLog = {
    fetchLogs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${ctx.rootState.apiUrl}/api/logs`, queryParams)
          .then(response => {
            const { items, total } = response.data;
            ctx.commit('LOG_LIST_SET', {
              items: items,
              total: total
            });
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchLog(ctx, { id }) {
        return new Promise((resolve, reject) => {
            axios
            .get(`${ctx.rootState.apiUrl}/api/logs/${id}`)
            .then(response => {
              ctx.commit('LOG_ITEM_SET', response.data);
              resolve(response)
            })
            .catch(error => reject(error))
        })
    },
}

export {
  stateLog,
  gettersLog,
  mutationsLog,
  actionsLog
}