/* Item Vuex State Management
import { stateItem, gettersItem, mutationsItem, actionsItem} from './item'
*/
import axios from '@axios';

const stateItem = {
  Items: [],
  ItemsTotal: 0,
  Item: {}
}

const gettersItem = {
};

const mutationsItem = {
  ITEM_LIST_SET(state, value) {
    state.Items = value.items;
    state.ItemsTotal = value.total;
  },
  ITEM_ITEM_SET(state, value) {
    state.Item = value;
  }
};

const actionsItem = {
    fetchItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${ctx.rootState.apiUrl}/api/items`, queryParams)
          .then(response => {
            const { items, total } = response.data;
            ctx.commit('ITEM_LIST_SET', {
              items: items,
              total: total
            });
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchItem(ctx, { id }) {
        return new Promise((resolve, reject) => {
            axios
            .get(`${ctx.rootState.apiUrl}/api/items/${id}`)
            .then(response => {
              ctx.commit('ITEM_ITEM_SET', response.data);
              resolve(response)
            })
            .catch(error => reject(error))
        })
    },
    updateItem(ctx, { id, entity }) {

      ctx.commit('ITEM_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/items/${id}`, entity)
        .then(response => {
          ctx.commit('ITEM_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    toggleDoNotSendItem(ctx, { id, entity, DoNotSend, comment }) {

      entity.DoNotSend = DoNotSend;

      ctx.commit('ITEM_ITEM_SET', entity);  // update immediately

      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/items/${id}/donotsend`, {
          DoNotSend: DoNotSend,
          Comment: comment
        })
        .then(response => {
          ctx.commit('ITEM_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    toggleDamagedItem(ctx, { id, entity, Damaged, comment }) {

      entity.Damaged = Damaged;

      ctx.commit('ITEM_ITEM_SET', entity);  // update immediately

      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/items/${id}/damaged`, {
          Damaged: Damaged,
          Comment: comment
        })
        .then(response => {
          ctx.commit('ITEM_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    toggleRefusedItem(ctx, { id, entity, Refused, comment }) {

      entity.RefusedDelivery = Refused;

      ctx.commit('ITEM_ITEM_SET', entity);  // update immediately

      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/items/${id}/refused`, {
          Refused: Refused,
          Comment: comment
        })
        .then(response => {
          ctx.commit('ITEM_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    toggleHeavyItem(ctx, { id, entity, Heavy, comment }) {

      entity.Heavy = Heavy;

      ctx.commit('ITEM_ITEM_SET', entity);  // update immediately

      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/items/${id}/heavy`, {
          Heavy: Heavy,
          Comment: comment
        })
        .then(response => {
          ctx.commit('ITEM_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    toggleDangerousItem(ctx, { id, entity, Dangerous, comment }) {

      entity.Dangerous = Dangerous;

      ctx.commit('ITEM_ITEM_SET', entity);  // update immediately

      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/items/${id}/dangerous`, {
          Dangerous: Dangerous,
          Comment: comment
        })
        .then(response => {
          ctx.commit('ITEM_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    createItem(ctx, { entity }) {

      ctx.commit('ITEM_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .post(`${ctx.rootState.apiUrl}/api/items/create`, entity)
        .then(response => {
          ctx.commit('ITEM_ITEM_SET', response.data); // allow for server-side data
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    fetchItemsLookup(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        if (queryParams.id) {
          axios
            .get(`${ctx.rootState.apiUrl}/api/items/${queryParams.id}`)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => reject(error));
        } else {
          axios
            .post(`${ctx.rootState.apiUrl}/api/items`, queryParams)
            .then(response => {
              resolve(response.data.items);
            })
            .catch(error => reject(error));
        }
      });
    },
}

export {
  stateItem,
  gettersItem,
  mutationsItem,
  actionsItem
}