/* Invoice Vuex State Management
import { stateInvoice, gettersInvoice, mutationsInvoice, actionsInvoice} from './invoice'
*/
import axios from '@axios';

const stateInvoice = {
  Invoices: [],
  InvoicesTotal: 0,
  Invoice: {}
}

const gettersInvoice = {
};

const mutationsInvoice = {
  INVOICE_LIST_SET(state, value) {
    state.Invoices = value.items;
    state.InvoicesTotal = value.total;
  },
  INVOICE_ITEM_SET(state, value) {
    state.Invoice = value;
  }
};

const actionsInvoice = {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${ctx.rootState.apiUrl}/api/invoices`, queryParams)
          .then(response => {
            const { items, total } = response.data;
            ctx.commit('INVOICE_LIST_SET', {
              items: items,
              total: total
            });
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchInvoice(ctx, { id }) {
        return new Promise((resolve, reject) => {
            axios
            .get(`${ctx.rootState.apiUrl}/api/invoices/${id}`)
            .then(response => {
              ctx.commit('INVOICE_ITEM_SET', response.data);
              resolve(response)
            })
            .catch(error => reject(error))
        })
    },
    updateInvoice(ctx, { id, entity }) {

      ctx.commit('INVOICE_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/invoices/${id}`, entity)
        .then(response => {
          ctx.commit('INVOICE_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    deleteInvoice(ctx, { id, entity }) {

      return new Promise((resolve, reject) => {
        axios
        .delete(`${ctx.rootState.apiUrl}/api/invoices/${id}`)
        .then(response => {
          ctx.commit('INVOICE_ITEM_SET', null); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Deleted." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    createInvoice(ctx, { entity }) {

      ctx.commit('INVOICE_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .post(`${ctx.rootState.apiUrl}/api/invoices/create`, entity)
        .then(response => {
          ctx.commit('INVOICE_ITEM_SET', response.data); // allow for server-side data
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    fetchInvoicesLookup(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        if (queryParams.id) {
          axios
            .get(`${ctx.rootState.apiUrl}/api/invoices/${queryParams.id}`)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => reject(error));
        } else {
          axios
            .post(`${ctx.rootState.apiUrl}/api/invoices`, queryParams)
            .then(response => {
              resolve(response.data.items);
            })
            .catch(error => reject(error));
        }
      });
    },
    creditOutstandingInvoice(ctx, { id, comment }) {

      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/invoices/${id}/creditoutstanding`, {
          Comment: comment
        })
        .then(response => {
          ctx.commit('INVOICE_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    refundInvoice(ctx, { id, comment, refundAmount }) {

      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/invoices/${id}/refund`, {
          Comment: comment,
          Amount: refundAmount
        })
        .then(response => {
          ctx.commit('INVOICE_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },


}

export {
  stateInvoice,
  gettersInvoice,
  mutationsInvoice,
  actionsInvoice
}