<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-badge bottom
               color="success"
               overlap
               offset-x="12"
               offset-y="12"
               class="ms-4"
               dot> -->
        <v-avatar size="40px"
                  v-bind="attrs"
                  color="primary"
                  class="ms-4 v-avatar-light-bg primary--text"
                  v-on="on">
          <v-img v-if="userData.Avatar"
                 :src="userData.Avatar"></v-img>
          <v-icon v-else
                  color="primary"
                  size="28">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-avatar>
      <!-- </v-badge> -->
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <!-- <v-badge bottom
                 color="success"
                 overlap
                 offset-x="12"
                 offset-y="12"
                 class="ms-4"
                 dot> -->
          <v-avatar size="40px"
                    color="primary"
                    class="ms-4 v-avatar-light-bg primary--text">
            <v-img v-if="userData.avatar"
                   :src="userData.avatar"></v-img>
            <v-icon v-else
                    color="primary"
                    size="28">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
        <!-- </v-badge> -->
        <div class="d-inline-flex flex-column justify-center ms-3"
             style="vertical-align:middle">
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userData.FullName || userData.Username }}
          </span>
          <small class="text--disabled text-capitalize">{{ userData.Role }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item @click="goProfile">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <user-menu-theme-switcher></user-menu-theme-switcher>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js';

import UserMenuThemeSwitcher from '@/components/UserMenuThemeSwitcher.vue'
import store from '@/store'
import { initialAbility } from '@/plugins/acl/config'
import { ref, watch, computed, getCurrentInstance } from '@vue/composition-api'
import { useRouter } from '@core/utils'

export default {
  components: {
    UserMenuThemeSwitcher
  },
  setup() {

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const user = computed(() => store.state.app.LOGGEDINUSER);
    const userData = JSON.parse(localStorage.getItem('userData'))

    const goProfile = () => {
      router.push({ name: 'page-account-settings' })
    }

    const logout = () => {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')

      // Reset ability
      vm.$ability.update(initialAbility)

      // Redirect to login page
      router.push({ name: 'auth-login' })
    }

    return {
      user,
      logout,
      goProfile,
      userData,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
