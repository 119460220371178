import {
  mdiAccountOutline,
  mdiCalendarOutline,
  mdiEmailOutline,
  mdiFileDocumentOutline,
  mdiFileOutline,
  mdiHomeOutline,
  mdiMessageTextOutline,
  mdiRuler,
  mdiShape,
  mdiMapMarker,
  mdiAccount,
  mdiCart,
  mdiEarth,
  mdiBookmarkCheck,
  mdiForklift,
  mdiBookOpenVariant,
  mdiPackageVariantClosed,
  mdiBookEditOutline,
  mdiFaceAgent,
  mdiCheckbook,
  mdiPaperclip,
  mdiCurrencyUsdOff,
  mdiFinance,
  mdiCog,
  mdiInformationOutline,
  mdiTortoise
} from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'dashboard',
    resource: 'Public',
    action: 'read'
  },
  {
    subheader: 'ORDERS',
    resource: 'Public',
    action: 'read'
  },
  {
    title: 'Book-In Item',
    icon: mdiBookOpenVariant,
    to: 'order-bookin',
    resource: 'BookIn'
  },
  {
    title: 'Orders',
    icon: mdiCart,
    to: 'order-list',
    resource: 'Orders'
  },
  {
    title: 'Unheld Orders',
    icon: mdiTortoise,
    to: 'unheld-order-list',
    resource: 'Orders'
  },
  {
    title: 'Quotes',
    icon: mdiBookEditOutline, // mdiFileSign in later versions?
    to: 'order-quoteslist',
    resource: 'Quotes'
  },
  {
    title: 'Customer Care',
    icon: mdiFaceAgent,
    to: 'order-customercare',
    resource: 'CustomerCare'
  },
  {
    title: 'Reports',
    icon: mdiFinance ,
    to: 'reports',
    resource: 'Reports'
  },
  {
    subheader: 'MEMBERS',
    resource: 'Customers'
  },
  {
    title: 'Members',
    icon: mdiAccount,
    to: 'member-list',
    resource: 'Customers'
  },
  {
    title: 'Addresses',
    icon: mdiMapMarker,
    to: 'address-list',
    resource: 'Customers'
  },
  {
    subheader: 'MANIFESTS',
    resource: 'Manifests'
  },
  {
    title: 'Manifests',
    icon: mdiForklift,
    to: 'manifest-list',
    resource: 'Manifests'
  },
  {
    subheader: 'ADMINISTRATION',
    resource: 'Administration',
  },
  {
    title: 'Products',
    icon: mdiRuler,
    to: 'product-list',
    resource: 'Administration'
  },
  {
    title: 'Order Extras',
    icon: mdiPaperclip ,
    to: 'orderextra-list',
    resource: 'Administration'
  },
  {
    title: 'Discount Codes',
    icon: mdiCurrencyUsdOff,
    to: 'discountcode-list',
    resource: 'Administration'
  },
  {
    title: 'Users',
    icon: mdiAccount,
    to: 'user-list',
    resource: 'Administration'
  },
  {
    subheader: 'REFERENCE',
    resource: 'Administration'
  },
  {
    title: 'Order Statuses',
    icon: mdiBookmarkCheck,
    to: 'orderstatus-list',
    resource: 'Administration'
  },
  {
    title: 'Product Categories',
    icon: mdiShape,
    to: 'productcategory-list',
    resource: 'Administration'
  },
  {
    title: 'Countries',
    icon: mdiEarth,
    to: 'country-list',
    resource: 'Administration'
  },
  {
    subheader: 'DEVELOPERS',
    resource: 'Super'
  },
  {
    title: 'Items',
    icon: mdiPackageVariantClosed,
    to: 'item-list',
    resource: 'Super'
  },
  {
    title: 'Invoices',
    icon: mdiCheckbook,
    to: 'invoice-list',
    resource: 'Super'
  },
  //{
  //   title: 'Create Test Order',
  //   icon: mdiBookOpenVariant,
  //   to: 'order-createtest',
  //   resource: 'Super'
  // },
  {
    title: 'Settings',
    icon: mdiCog,
    to: 'setting-list',
    resource: 'Super'
  },
  {
    title: 'Log',
    icon: mdiInformationOutline,
    to: 'log-list',
    resource: 'Super'
  }
]
