const dashboard = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      layout: 'content',
      resource: 'Dashboard'
    },
  }
]

export default dashboard
