/* Item Vue Router Routes */
const items = [
  {
    path: '/items',
    name: 'item-list',
    component: () => import('@/views/item/ItemList.vue'),
    meta: {
      layout: 'content',
      resource: 'Orders',
      action: 'read'
    },
  },
  {
    path: '/items/create',
    name: 'item-create',
    component: () => import('@/views/item/ItemCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'BookIn',
      action: 'create'
    },
  },
  {
    path: '/items/:id',
    name: 'item-view',
    component: () => import('@/views/item/ItemView.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Orders',
      action: 'read'
    },
  },
  {
    path: '/items/edit/:id',
    name: 'item-edit',
    component: () => import('@/views/item/ItemEdit.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'BookIn',
      action: 'update'
    },
  },
]

export default items
