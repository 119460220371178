/* ProductCategory Vuex State Management
import { stateProductCategory, gettersProductCategory, mutationsProductCategory, actionsProductCategory} from './productcategory'
*/
import axios from '@axios';

const stateProductCategory = {
  ProductCategories: [],
  ProductCategoriesTotal: 0,
  ProductCategory: {}
}

const gettersProductCategory = {
};

const mutationsProductCategory = {
  PRODUCTCATEGORY_LIST_SET(state, value) {
    state.ProductCategories = value.items;
    state.ProductCategoriesTotal = value.total;
  },
  PRODUCTCATEGORY_ITEM_SET(state, value) {
    state.ProductCategory = value;
  }
};

const actionsProductCategory = {
    fetchProductCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${ctx.rootState.apiUrl}/api/productcategories`, queryParams)
          .then(response => {
            const { items, total } = response.data;
            ctx.commit('PRODUCTCATEGORY_LIST_SET', {
              items: items,
              total: total
            });
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchProductCategory(ctx, { id }) {
        return new Promise((resolve, reject) => {
            axios
            .get(`${ctx.rootState.apiUrl}/api/productcategories/${id}`)
            .then(response => {
              ctx.commit('PRODUCTCATEGORY_ITEM_SET', response.data);
              resolve(response)
            })
            .catch(error => reject(error))
        })
    },
    updateProductCategory(ctx, { id, entity }) {

      ctx.commit('PRODUCTCATEGORY_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/productcategories/${id}`, entity)
        .then(response => {
          ctx.commit('PRODUCTCATEGORY_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    createProductCategory(ctx, { entity }) {

      ctx.commit('PRODUCTCATEGORY_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .post(`${ctx.rootState.apiUrl}/api/productcategories/create`, entity)
        .then(response => {
          ctx.commit('PRODUCTCATEGORY_ITEM_SET', response.data); // allow for server-side data
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    fetchProductCategoriesLookup(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        if (queryParams.id) {
          axios
            .get(`${ctx.rootState.apiUrl}/api/productcategories/${queryParams.id}`)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => reject(error));
        } else {
          axios
            .post(`${ctx.rootState.apiUrl}/api/productcategories`, queryParams)
            .then(response => {
              resolve(response.data.items);
            })
            .catch(error => reject(error));
        }
      });
    },
}

export {
  stateProductCategory,
  gettersProductCategory,
  mutationsProductCategory,
  actionsProductCategory
}