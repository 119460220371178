/* User Vuex State Management
import { stateUser, gettersUser, mutationsUser, actionsUser} from './user'
*/
import axios from '@axios';

const stateUser = {
  Users: [],
  UsersTotal: 0,
  User: {}
}

const gettersUser = {
};

const mutationsUser = {
  USER_LIST_SET(state, value) {
    state.Users = value.items;
    state.UsersTotal = value.total;
  },
  USER_ITEM_SET(state, value) {
    state.User = value;
  }
};

const actionsUser = {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${ctx.rootState.apiUrl}/api/users`, queryParams)
          .then(response => {
            const { items, total } = response.data;
            ctx.commit('USER_LIST_SET', {
              items: items,
              total: total
            });
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
        return new Promise((resolve, reject) => {
            axios
            .get(`${ctx.rootState.apiUrl}/api/users/${id}`)
            .then(response => {
              ctx.commit('USER_ITEM_SET', response.data);
              resolve(response)
            })
            .catch(error => reject(error))
        })
    },
    updateUser(ctx, { id, entity }) {

      ctx.commit('USER_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .put(`${ctx.rootState.apiUrl}/api/users/${id}`, entity)
        .then(response => {
          ctx.commit('USER_ITEM_SET', response.data); // allow for server-side data
          ctx.commit('SNACKBAR_ADD', { color: "secondary", message: " Saved." });
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    createUser(ctx, { entity }) {

      ctx.commit('USER_ITEM_SET', entity);  // update immediately
      return new Promise((resolve, reject) => {
        axios
        .post(`${ctx.rootState.apiUrl}/api/users/create`, entity)
        .then(response => {
          ctx.commit('USER_ITEM_SET', response.data); // allow for server-side data
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
    fetchUsersLookup(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        if (queryParams.id) {
          axios
            .get(`${ctx.rootState.apiUrl}/api/users/${queryParams.id}`)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => reject(error));
        } else {
          axios
            .post(`${ctx.rootState.apiUrl}/api/users`, queryParams)
            .then(response => {
              resolve(response.data.items);
            })
            .catch(error => reject(error));
        }
      });
    },
}

export {
  stateUser,
  gettersUser,
  mutationsUser,
  actionsUser
}