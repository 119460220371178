/* OrderLog Vuex State Management
import { stateOrderLog, gettersOrderLog, mutationsOrderLog, actionsOrderLog} from './orderlog'
*/
import axios from '@axios';

const stateOrderLog = {
  OrderLogs: [],
  OrderLogsTotal: 0,
  OrderLog: {}
}

const gettersOrderLog = {
};

const mutationsOrderLog = {
  ORDERLOG_LIST_SET(state, value) {
    state.OrderLogs = value.items;
    state.OrderLogsTotal = value.total;
  },
  ORDERLOG_ITEM_SET(state, value) {
    state.OrderLog = value;
  }
};

const actionsOrderLog = {
    fetchOrderLogs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${ctx.rootState.apiUrl}/api/orderlogs`, queryParams)
          .then(response => {
            const { items, total } = response.data;
            ctx.commit('ORDERLOG_LIST_SET', {
              items: items,
              total: total
            });
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    createOrderLog(ctx, { order, title, comment }) {

      let entity = {
        OrderId: order.Id,
        Title: title,
        Notes: comment
      };
      return new Promise((resolve, reject) => {
        axios
        .post(`${ctx.rootState.apiUrl}/api/orderlogs/create`, entity)
        .then(response => {
          ctx.commit('ORDERLOG_ITEM_SET', response.data); // allow for server-side data
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data) return reject(error.response.data);
          return reject(error);
        })
      })
    },
}

export {
  stateOrderLog,
  gettersOrderLog,
  mutationsOrderLog,
  actionsOrderLog
}