/* Item Vuex State Management
import { stateDashboard, gettersDashboard, mutationsDashboard, actionsDashboard} from './dashboard'
*/
import axios from '@axios';

const stateDashboard = {
  DashboardArrivals: [],
  DashboardIssues: [],
  DashboardManifests: {
    Guernsey: null,
    Jersey: null
  }
}

const gettersDashboard = {
};

const mutationsDashboard = {
  DASHBOARD_ARRIVALS_SET(state, value) {
    state.DashboardArrivals = value.items;
  },
  DASHBOARD_ISSUES_SET(state, value) {
    state.DashboardIssues = value.items;
  },
  DASHBOARD_MANIFEST_SET(state, value) {
    state.DashboardManifests[value.manifest.CountryId_Name] = value;
  }
};

const actionsDashboard = {
    fetchArrivalsDashboard(ctx) {
      return new Promise((resolve, reject) => {
        axios.get(`${ctx.rootState.apiUrl}/api/dashboard/arrivals`)
        .then(response => {
          ctx.commit('DASHBOARD_ARRIVALS_SET', {
            items: response.data
          });
          resolve(response)
        })
        .catch(error => reject(error))
      })
    },
    fetchIssuesDashboard(ctx) {
      return new Promise((resolve, reject) => {
        axios.get(`${ctx.rootState.apiUrl}/api/dashboard/issues`)
        .then(response => {
          ctx.commit('DASHBOARD_ISSUES_SET', {
            items: response.data
          });
          resolve(response)
        })
        .catch(error => reject(error))
      })
    },
    fetchManifestDashboard(ctx, { location }) {
      return new Promise((resolve, reject) => {
        axios.post(`${ctx.rootState.apiUrl}/api/dashboard/manifest`, {
          location
        })
        .then(response => {
          ctx.commit('DASHBOARD_MANIFEST_SET', response.data);
          resolve(response)
        })
        .catch(error => reject(error))
      })
    },
}

export {
  stateDashboard,
  gettersDashboard,
  mutationsDashboard,
  actionsDashboard
}