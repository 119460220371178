/* User Vue Router Routes */
const users = [
  {
    path: '/users',
    name: 'user-list',
    component: () => import('@/views/user/UserList.vue'),
    meta: {
      layout: 'content',
      resource: 'Users',
      action: 'read'
    },
  },
  {
    path: '/users/create',
    name: 'user-create',
    component: () => import('@/views/user/UserCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'Users',
      action: 'create'
    },
  },
  {
    path: '/users/:id',
    name: 'user-view',
    component: () => import('@/views/user/UserView.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Users',
      action: 'read'
    },
  },
  {
    path: '/users/edit/:id',
    name: 'user-edit',
    component: () => import('@/views/user/UserEdit.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Users',
      action: 'update'
    },
  },
]

export default users
